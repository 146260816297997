.wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width: 350px) {
    .wrapper {
        margin-left: -40px;
    }
}
