.search,
.search-result {
    width: 361px;
}
.search {
    position: relative;

    border: 1.5px solid transparent;
    height: var(--search-height);
    padding-left: 16px;
    background-color: #f1f1f1;
    border-radius: var(--search-border-radius);
    display: flex;
    input {
        padding-right: 30px;
        flex: 1;
        height: 100%;
        caret-color: var(--primary);
        color: var(--black);
        font-size: 1.6rem;
        background-color: transparent;
    }
    input:not(:placeholder-shown) ~ .search-btn {
        color: rgba(22, 24, 35, 0.75);
    }
    &::after {
        content: '';
        position: absolute;
        height: 28px;
        width: 1px;
        background-color: rgba(22, 24, 35, 0.34);
        top: calc((var(--search-height) - 28px) / 2);
        right: var(--search-button-width);
    }
    &:focus-within {
        border: 1.5px solid rgba(22, 24, 35, 0.2);
    }
}
.search-tile {
    padding: 5px 12px;
    color: rgba(22, 24, 35, 0.5);
    font-size: 1.4rem;
    font-weight: 600;
}
.clear,
.loading {
    position: absolute;
    right: calc(var(--search-button-width) + 10px);
    top: 50%;
    transform: translateY(-50%);
    color: rgba(22, 24, 35, 0.34);
    cursor: pointer;
}
.loading {
    animation: loadingRoll 1s linear infinite;
}
@keyframes loadingRoll {
    from {
        transform: translateY(-50%) rotate(0);
    }
    to {
        transform: translateY(-50%) rotate(360deg);
    }
}
.search-btn {
    width: var(--search-button-width);
    height: 100%;
    font-size: 1.8rem;
    color: rgba(22, 24, 35, 0.34);
    border-top-right-radius: var(--search-border-radius);
    border-bottom-right-radius: var(--search-border-radius);
    cursor: pointer;

    &:hover {
        background-color: rgba(22, 24, 25, 0.03);
    }
    &:active {
        background-color: rgba(22, 24, 25, 0.06);
    }
}
@media (max-width: 800px) {
    .search {
        margin-left: 10px;
        width: 296px;
        padding-left: 14px;
        // input::placeholder {
        //     color: transparent;
        // }
    }
}
@media (max-width: 680px) {
    .search {
        width: 305px;
        padding-left: 14px;
        // input::placeholder {
        //     color: transparent;
        // }
    }
}
@media (max-width: 510px) {
    .search {
        width: 240px;
        height: 40px;
        padding-left: 10px;

        input {
            padding-right: 10px;
            &::placeholder {
                color: transparent;
            }
        }
        .search-btn {
            width: 34px;
        }
    }
}
