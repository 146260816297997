.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.container {
    max-width: 100%;
    margin-top: var(--default-layout-header-height);
    width: var(--default-layout-width-content);
    display: flex;
}
.content {
    flex: 1;
    overflow-y: auto;
    max-height: calc(100vh - 60px);
}
