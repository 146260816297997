/// Menu wrapper
.menu-list {
    width: 224px;
    .menu-item {
        margin-left: 0;
        display: flex;
        justify-content: flex-start;
        padding: 11px 16px;
        line-height: 1.5;
        font-weight: 600;
        color: var(--text-color);
    }
}
.menu-popper {
    padding-bottom: 8px;
    display: flex;
    flex-direction: column;
}
/// Menu Item
.menu-item {
    width: 100%;
    border-radius: 0;
    &:hover {
        background-color: rgba(22, 24, 35, 0.03);
    }
    &.separate {
        border-top: solid 1px rgba(84, 84, 84, 0.22);
    }
}
.MenuScrollBar {
    overflow-y: auto;
}
//// header
.header {
    position: relative;
    width: 100%;
    flex-shrink: 0;
}
.header-title {
    text-align: center;
    font-size: 1.6rem;
}
.back-btn {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    font-size: 1.6rem;
    background-color: #fff;
    cursor: pointer;
}
