.container {
    padding: 32px 24px;
}
.heading {
    width: 624px;
}
.header {
    display: flex;
}

.avatarImage {
    width: 116px;
    height: 116px;
    border-radius: 50%;
    object-fit: cover;
}

.userTitle {
    margin-left: 20px;
}

.userNickname {
    font-weight: 700;
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 4px;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    word-break: break-word;
}

.userName {
    margin-bottom: 15px;
    display: flex;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.028px;
    text-overflow: ellipsis;
    height: 25px;
    overflow: hidden;
    max-width: 450px;
    white-space: nowrap;
    align-items: flex-end;
}

.followBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-box-pack: center;
    min-width: 190px;
    line-height: 22px;
}

.userSocial {
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-top: 22px;
    color: rgba(18, 18, 18, 0.75);
}

.socialItem {
    margin-right: 20px;
}

.userCount {
    margin-right: 6px;
    font-weight: 700;
    color: rgba(22, 24, 35, 1);
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.028px;
}
.userMoreInfo {
    margin-top: 10px;
}
.userInfoItem {
    display: flex;
    align-items: center;
}
/////user nav///
.user-nav {
    display: flex;
    margin-top: 20px;
    margin-bottom: 8px;
    border-bottom: solid 1px #eeeef0;
    position: relative;
}
.user-nav-item {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.028px;

    display: flex;
    align-items: center;
    flex-shrink: 0;
    height: 44px;
    justify-content: center;

    width: 122px;
    cursor: pointer;
    text-align: center;
    color: #73747b;
}

.user-nav--active {
    color: rgba(22, 24, 35, 1);
}
.user-nav-line {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    height: 2px;
    width: 122px;
    background-color: rgba(22, 24, 35, 1);
    transition: 0.4s;
    transform: translateX(0);
}
.liked.user-nav--active ~ .user-nav-line {
    transform: translateX(100%);
}
.liked:hover ~ .user-nav-line {
    transform: translateX(100%);
}
.videos:hover ~ .user-nav-line {
    transform: translateX(0);
}
///user liked///
.liked-content {
    margin-top: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #73747b;
}
.liked-title {
    font-size: 2.4rem;
    line-height: 30px;
    font-weight: 700;
    color: rgb(22, 24, 35);
    margin-top: 15px;
}
.liked-desc {
    font-size: 1.6rem;
    line-height: 21px;
    letter-spacing: 0.03px;
    font-weight: 400;
    color: rgba(22, 24, 35, 0.75);
    margin-top: 8px;
}
///videos//
.videos-wrapper {
    cursor: pointer;
    display: grid;
    grid-gap: 24px 16px;
    grid-template-columns: repeat(auto-fill, minmax(184px, 1fr));
}
@media (max-width: 550px) {
    .avatarImage {
        width: 75px;
        height: 75px;
    }
    .followBtn {
        min-width: 160px;
    }
}
@media (max-width: 400px) {
    .container {
        padding: 17px;
    }
    .avatarImage {
        width: 65px;
        height: 65px;
    }
    .followBtn {
        min-width: 130px;
    }
    .userSocial {
        margin-top: 12px;
    }

    .socialItem {
        margin-right: 7px;
        font-size: 14px;
    }
    .userCount {
        margin-right: 4px;
        font-size: 15px;
    }
    .liked-content {
        margin-top: 35px;
    }
}
