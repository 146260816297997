@media (max-width: 1071px) {
    .menu-item {
        .menu-title {
            display: none;
        }
    }
}
.menu-item {
    display: flex;
    font-size: 1.8;
    font-weight: 700;
    align-items: center;
    padding: 8px;
    border-radius: 4px;
    transition: background-color ease-in-out 200ms;
    .icon {
        display: flex;
    }
    &.active {
        color: var(--primary);
        .icon-active {
            display: flex;
        }
        .icon {
            display: none;
        }
    }
    &:hover {
        background-color: rgba(22, 24, 35, 0.03);
    }
}
.menu-title {
    margin-left: 10px;
}
.icon-active {
    display: none;
}
