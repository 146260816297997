@import 'normalize.css';

// @import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700&display=swap');
:root {
    --primary: #fe2c55;
    --black: #000;
    --white: #fff;
    --text-color: #333;
    /// default layout
    --default-layout-header-height: 60px;
    --default-layout-width-content: 100%;
    --default-layout-horizontal-spacer: 24px;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
html {
    font-size: 62.5%;
}
button {
    font-family: 'TikTokFont', sans-serif;
}
//font embeded
@font-face {
    font-family: TikTokFont;
    src: url('/assets/fonts/TikTokFont-Regular.woff2');
    font-weight: 400;
}
@font-face {
    font-family: TikTokFont;
    src: url('/assets/fonts/TikTokFont-Semibold.woff2');
    font-weight: 600;
}
@font-face {
    font-family: TikTokFont;
    src: url('/assets/fonts/TikTokFont-Bold.woff2');
    font-weight: 700;
}

//
body {
    font-family: 'TikTokFont', sans-serif;
    font-size: 1.6rem;
    line-height: 1.5;
    text-rendering: optimizeSpeed;
}
button,
input,
[tabindex] {
    outline: none;
    border: none;
}
a {
    color: var(--text-color);
    text-decoration: none;
}
body {
    .tippy-arrow {
        color: rgba(84, 84, 84, 0.92);
    }
    .tippy-box {
        border-radius: 8px;
        font-weight: 600;
        font-size: 1.6rem;
        line-height: 2.2rem;
        background-color: rgba(84, 84, 84, 0.92);
    }
    .tippy-content {
        padding: 8px 9px;
    }
}

html *::-webkit-scrollbar {
    border-radius: 0;
    width: 8px;
}

html ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(22, 24, 35, 0.06);
}

html ::-webkit-scrollbar-track {
    border-radius: 0px;
    background-color: rgba(0, 0, 0, 0);
}
////////////////

.modal-header {
    height: 50px;
}
@media (max-width: 460px) {
    .modal-title {
        font-size: 21px !important;
    }
}
