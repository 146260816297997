.wrapper {
    background-color: #000;
    height: 600px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.content {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.title {
    text-align: center;
    color: hsl(0deg 0% 100% / 90%);
    font-size: 2.4rem;
}
.desc {
    color: hsl(0deg 0% 100% / 90%);
    font-size: 1.6rem;
}
.back-btn {
    background-color: hsla(0, 0%, 100%, 0.2);
    border: 1px solid transparent;
    border-radius: 3px;
    color: hsla(0, 0%, 100%, 0.9);
    // cursor: pointer;
    // display: inline-block;
    // font-size: 1.6rem;
    margin-top: 10px;
    padding: 10px 15px;
    &:hover {
        border: 1px solid #fff;
    }
}
