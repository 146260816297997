.wrapper {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    --search-border-radius: 92px;
    --search-height: 46px;
    --search-button-width: 52px;
    width: 100%;
    height: var(--default-layout-header-height, 60px);
    box-shadow: 0px 1px 1px rgb(0 0 0 / 12%);
    display: flex;
    justify-content: center;
    background-color: var(--white);
}
.inner {
    height: 100%;
    width: var(--default-layout-width-content);
    padding: 0 var(--default-layout-horizontal-spacer);
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.logo {
}

///
.actions {
    display: flex;
    align-items: center;
}
///
.more-btn {
    margin-left: 28px;
    font-size: 2rem;
    padding: 4px 8px;
    background-color: transparent;
    cursor: pointer;
}
.menu-items {
    width: 224px;
}
.action-btn {
    background-color: transparent;
    font-size: 2.2rem;
    color: #161823;
    padding: 4px 12px;
    cursor: pointer;
}
.CurrentUser {
    display: flex;
    align-items: center;
}
.user-avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 12px;
    cursor: pointer;
}
.search-btn {
    display: none;
    background-color: transparent;
}

.actions-btn {
    display: flex;
}
.hidden {
    display: none !important;
}
.show {
    display: block !important;
}
@media (max-width: 680px) {
    .actions-btn {
        display: none;
    }
    .more-btn {
        margin-left: 10px;
    }
}
@media (max-width: 510px) {
    .logo {
        width: 100px;
    }
    .inner {
        padding: 0 10px;
    }
}
@media (max-width: 420px) {
    .actions-btn {
        display: flex;
    }
    .upload-btn {
        display: none;
    }
    .search-btn {
        display: block;
        margin-left: 10px;
    }
    .upload-video {
        display: none;
    }
    .search {
        display: none;
    }
}
