.wrapper {
    // position: relative;
    // width: 208px;
    position: relative;
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
}
.video {
    width: 208px;
    height: 275px;
    object-fit: cover;
    border-radius: 5px;
}
.liked {
    display: flex;
    align-items: center;
    color: rgba(255, 255, 255, 1);
    font-weight: 600;
    font-size: 1.6rem;
    position: absolute;
    left: 10px;
    bottom: 40px;
}
.liked-amount {
    margin-left: 4px;
}
.title {
    margin: 4px 4px 0px 8px;
    color: rgba(22, 24, 35, 1);
    cursor: pointer;
    font-weight: 600;
    font-size: 1.6rem;
    //
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
///videos///
